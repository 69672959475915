import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='footer'>
            <h1>Dear DoubleDex Customers</h1>
            <p>

After a fulfilling decade as the founder and CEO of Double Dex LLC, I have made the difficult decision to close our doors. Throughout these years, we prospered, selling over $60 million in high-quality Macs and parts to hundreds of thousands of customers. I'm immensely proud of what we've achieved—not just in sales and happy customers, but in creating a supportive and thriving workplace for our team.<br></br><br></br>

Our journey included adding proprietary software automation, and constantly adapting our strategy to new products and e-commerce market changes, which kept us competitive and often led to growth. Because of our high standards and superior marketplace metrics we were honored to be among the first selected by Amazon for their Certified Refurbished Apple products program (now, renewed) and similarly recognized by eBay in their various refurbished programs.<br></br><br></br>

With operations winding down over the past few months, I find myself missing our wonderful team and the community we built. I am now looking to bring my experience and enthusiasm to a new challenge. I thrive on solving complex business problems and streamlining operations, especially in environments that care deeply about their impact.<br></br><br></br>

If you are one of our wonderful customers from the past 10 years, please don’t hesitate to reach out at <a id="a-footer" href="mailto:sales@doubledex.com">sales@doubledex.com</a> and we can assist you in the best way possible.  We are not equipped to do any repairs or sales but I can try and point you in the right direction for support.<br></br><br></br>

Thank you for everything.<br></br><br></br>

Warm regards,<br></br>
John Sigurdson<br></br><br></br>

Founder and CEO of Double Dex LLC
</p>
        </div>
    )
}

export default Footer;